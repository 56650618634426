(function( window, $ ) {
    var loop;
    
    loop = (function() {
    	// console.log("loop()", $('#header').html());
        var prefixes = '-webkit- -moz- -o- -ms- '.split(' '),
            buildCSSString = this.buildCSSString,
            injectCSS = this.injectCSS,
            $window = $(window),
            queries = [
                {
                    context: 'mobile',
                    match: function() {
                        $window.on('resize.mobile', $.throttle(250, mobile.resize));
                        
                        if ($('.widget-featured').length) {
                            mobile.featuredInit();
                        }
                        
                        mobile.headerInit();
                    },
                    unmatch: function() {
                        $window.off('resize.mobile');
                        
                        if ($('.widget-featured').length) {
                            mobile.featuredDestroy();
                        }
                        
                        mobile.headerDestroy();
                    }
                },
                {
                    context: 'desktop',
                    match: function() {
                        // console.log('Desktop callback');
                    },
                    unmatch: function() {
                        // console.log('Leaving desktop context!');
                    }
                }
            ];
            
        var mobile = {};
        mobile = {
            headerDrawerState: 'closed',
            headerInit: function() {
                
                // Header drawer
                var $headerDrawer = $('<div/>', {
                    id: 'header-drawer',
                    css: {
                        'visibility': 'hidden'
                    }
                }).appendTo($('body'));
                
                var makeVisible = function() {
                    $headerDrawer.removeAttr('style');
            
                    makeVisible = function() { // Run once
                        return;
                    };
                };
                
                $headerDrawer.append($('#nav-main'), $('#nav-social'));

                // Menu toggle
                var $menuToggle = $('<a/>', {
                    id: 'menu-toggle',
                    html: '<span class="pa">&nbsp;<span class="ir s"></span></span><span class="str">Menu</span>'
                });

                $menuToggle
                    .appendTo($("#header .wrapper"))
                    .on('click.toggleDrawer', function(ev) {
                        ev.preventDefault();
                        makeVisible();
                        mobile.headerDrawerToggle();
                    });
                
            },
            headerDestroy: function() {
                if (mobile.headerDrawerState === 'open') {
                    mobile.headerDrawerToggle();
                }
                
                $("#header .wrapper .inner-wrapper").eq(0).prepend($('#nav-social'));
                $("#header .wrapper .inner-wrapper").eq(0).append($('#nav-main'));
                
                $('#menu-toggle')
                    .off('click.toggleDrawer')
                    .detach()
                    .remove();

                $('#header-drawer')
                    .detach()
                    .remove();
            },
            headerDrawerCSS: function() {
                var drawerHeight = $('#header-drawer').outerHeight(),
                    css = {
                        'transform': 'translateY(' + drawerHeight + 'px)',
                        'background': 'red'
                    },
                    selector = '.open-drawer .wrapper, .open-drawer .full-width, .open-drawer #footer';
                
                return buildCSSString(selector, css);
            },
            headerDrawerToggle: function() {
                var state = mobile.headerDrawerState;
                
                // Open, septuagenarian? Uh, open, saddle soap? Open, sesame?  
                if (state !== 'open') {
                    injectCSS('mobile-css', mobile.headerDrawerCSS());
                    
                    $('body').addClass('open-drawer');
                    mobile.headerDrawerState = 'open';
                } else {
                    $('body').removeClass('open-drawer');
                    mobile.headerDrawerState = 'closed';
                }
            },
            featuredInit: function() {
                var $widget = $('.widget-featured'),
                    sections = $widget.find('.feature-column'),
                    filterNavHTML = '<nav class="filter-nav"><ul>';
                
                // Assemble HTML
                for (var i = 0; i < sections.length; i++) {
                    var $elem = $(sections[i]);
                    
                    filterNavHTML += '<li><a href="' + $elem.attr('id') + '">' + $elem.data('section-title') + '</a></li> ';
                }
                
                var $sidebar = $('<aside />', {
                    'class': 'sidebar',
                    'html': filterNavHTML
                });
                $sidebar.on('click', 'a', function(ev) {
                    var $me = $(this),
                        $targetSection = $('#' + $me.attr('href')),
                        sectionsToHide = sections.filter(function(i) {
                            return !$(this).is($targetSection);
                        });
                    
                    sectionsToHide.hide();
                    $sidebar.find('li').removeClass('active');
                    
                    $me.closest('li').addClass('active');
                    $targetSection.show();
                    
                    ev.preventDefault();
                }).prependTo($('#main'));
                
                $sidebar.find('li:first-child a').trigger('click');
                
                // Add class
                $('#main').addClass('layout-sidebar');
            },
            featuredDestroy: function() {
                var $sidebar = $('#main').find('> .sidebar');
                $sidebar
                    .off('click')
                    .detach()
                    .remove();
                    
                $('#main').removeClass('layout-sidebar');
                
                $('.widget-featured .feature-column').show();
            },
            resize: function() {
                $('body').trigger('equalize');
            }
        };
        
        buildCSSString = function(selector, cssObject) {
            var builtCSS = selector + '{';
            
            $.each(cssObject, function(key, property) {
                if (key === 'transform') {
                    var prefixedString = '';
                    
                    for (var i = 0; i < prefixes.length; i++) {
                        prefixedString += prefixes[i] + key + ':' + property + ';';
                    }
                    
                    builtCSS += prefixedString;
                }
            });
            
            builtCSS += '}';
            
            return builtCSS;
        };
        
        injectCSS = function(id, css) {
            $('#' + id).remove();
            $('head').append('<style id="'+id+'" type="text/css">' + css + '</style>');
        };

        init = function() {
            MQ.init(queries);
            
            // Meta
            var $metaGroups = $('.meta .group');
            
            if ($metaGroups.length > 0) {
                // Use internal variable reference to ensure local function is called
                // meta.apply(this, [$metaGroups]);
                
                // Check for single anchor children to add text-overflow: ellipsis;
                $metaGroups.each(function() {
                    var $definition = $(this).find('.definition'),
                        trimmedHTML = $.trim($definition.html());

                    // Remove empty textnodes/whitespace
                    $definition.html(trimmedHTML);

                    // Get contents
                    var $contents = $definition.contents();

                    // If only 1 child element and it's an anchor
                    if (($contents.length === 1) && ($($contents[0]).is('a'))) {
                        $definition.addClass('single-link');
                    }
                });
            }
            
            // Grid toggle and hover link effect
            $('.widget-grid')
                .gridToggle()
                .on('mouseenter', '.media', function(){
                    var $item = $(this).closest('.item');
                    $item.addClass('hover');
                }).on('mouseleave', '.media', function(){
                    var $item = $(this).closest('.item');
                    $item.removeClass('hover');
                });
            
            // Condense
            $('.layout-article .detail .condense').condense({
                condensedLength: 500,
                moreText: "&hellip; more",
                lessText: "",
                ellipsis: "",
                moreSpeed: 0,
                lessSpeed: 0
            });

            var $carousel = $('.slideshow-carousel-container');
            if($carousel.length) {
                var carousel = {};
                if ($carousel.hasClass('live-images-carousel')) {
                    carousel.options = {
                        "cellAlign": "left",
                        "contain": true,
                        "wrapAround": true,
                        "fullscreen": true,
                        "adaptiveHeight": false,
                        "imagesLoaded": true
                    }
                } else {
                    carousel.options = {
                        "cellAlign": "left",
                        "contain": true,
                        "wrapAround": true,
                        "prevNextButtons": false,
                        "adaptiveHeight": false,
                        "autoPlay": true,
                        "imagesLoaded": true
                    }
                }
                $carousel.flickity(carousel.options);
                $carousel.on( 'fullscreenChange.flickity', function( event, isFullscreen ) {
                    var $carouselContainer = $('.slideshow-carousel-container');
                    if (isFullscreen) {
                        var carouselTopOffset = $carouselContainer.offset().top;
                        $carouselContainer.css('top', (carouselTopOffset * -1) + 'px');
                    } else {
                        $carouselContainer.css('top', '0px');
                    }

                });

            }
            
            $(".timeago").timeago().addClass("timeago-complete").removeClass("timeago-pending");
            
            // Bootstrap tooltips
            $('#nav-social').tooltip({
                selector: 'a'
            });
    		
            // Lazyload
            $("img.lazyload").lazyload({effect : "fadeIn", failure_limit: 1000000, threshold: 500});
            
            $(".filter-nav .active a").each(function(){
            	var $this = $(this);
            	var txt = $this.text();
            	$this.html("<span class='str'>" + txt + "</span>&nbsp;<span class='pa'><span class='ir s'></span></span>");
            });

            // Grid widget
            $('.widget-grid-3').equalize({
                step: 3,
                images: true
            });
            
        	if (MQ.context == "desktop") {
                $(".video-trigger").colorbox({
                	iframe: true,
                	innerWidth: "800px",
                	innerHeight: "450px"
                });
                $(".photo-trigger").colorbox({
                	innerWidth: "800px",
                	innerHeight: "450px",
                	scalePhotos: true
                });
        	} else {
        		var width = $(window).width(),
        			height = Math.ceil(width / 16 * 9 * 0.95);
                $(".video-trigger").colorbox({
                	iframe: true,
                	width: "95%",
                	innerHeight: height
                });
                $(".photo-trigger").colorbox({
                	width: "95%",
                	innerHeight: height,
                	scalePhotos: true
                });
        	}

        	// Downloads
            $(".downloads-trigger").colorbox({
            	inline: true,
            	onComplete: function() {
            		$(".download-links a").on("click", function() {
            			$(this).text("Downloading…");
            			$("#download-sharing").css("height", "auto");
            			$.colorbox.resize()
            		});
            	},
            	onClosed: function() {
            		$(".download-links a").each(function() {
            			$(this).text($(this).data("label"));
            			$("#download-sharing").css("height", 0);
            		});
            	}
            });

            // FitVids
            $('.widget-video .item .media, .widget-featured .item .media, .popup-video').fitVids();
            $('.layout-article iframe[src*="youtube"], .layout-article iframe[src*="vimeo"]').parent().fitVids();

            //Resize blog post images
            $('.layout-article-blog-post img').attr('height', 'auto');
            
            // Sharing
            $('#sharrre-twitter, #sharrre-twitter-popup').sharrre({
				share: {
					twitter: true
				},
				enableHover: false,
				enableTracking: true,
				buttons: { twitter: {} },
				click: function(api, options){
					api.simulateClick();
					api.openPopup('twitter');
				}
			});
            
            $('#sharrre-facebook, #sharrre-facebook-popup').sharrre({
				share: {
					facebook: true
				},
				enableHover: false,
				enableTracking: true,
				click: function(api, options){
					api.simulateClick();
					api.openPopup('facebook');
				}
			});

            $('#sharrre-pinterest, #sharrre-pinterest-popup').sharrre({
				share: {
					pinterest: true
				},
				enableHover: false,
				enableTracking: true,
				click: function(api, options){
					api.simulateClick();
					api.openPopup('pinterest');
				},
				buttons: {
					pinterest: {
						media: $('#sharrre-pinterest').data("media"),
						description: $('#sharrre-pinterest').data("description")
					}
				}
			});

            $("a[href^=http]:not(.video-trigger,.photo-trigger)").each(function(){
            	var excludes = ['rhiansheehan.com'];
            	for ( var i=0; i < excludes.length; i++ ) {
            		if ( this.href.indexOf(excludes[i]) != -1 ) {
            			return true;
            		}
            	}
            	if ( this.href.indexOf(location.hostname) == -1 ) {
            		$(this).click(function() { return true; }); 
            		$(this).attr({ target: "_blank" });
            		$(this).click();
            	}
    	   });
            

			$('.sub-trigger').on('click', function(){
				$('.title').removeClass('show-trigger').addClass('show-form');
				$('.sub-email').focus();
			});
			

        };
        
        return {
            init: init
        };
    })();
    
    $(function(){
        loop.init();
    });
    
})( window, jQuery );