/*  
    TODO:
    
    Support for appended items (ajax)
    
*/

(function($, window, document, undefined) {
    var equalize = {
        init: function(options, elem) {
            var me = this;

            // Mix in the passed-in options with the default options
            me.options = $.extend({}, this.options, options);

            // Save the element reference, both as a jQuery reference and a normal reference
            me.elem = elem;
            me.$elem = $(elem);
            
            // Regex herp derp
            me.re = new RegExp(me.options.property + '(\\s)*:(\\s)*(\\d)*(\\w|%){2}(\\s)*;', 'gi');
            
            // Bindings
            me._bindings();
            
            // Create empty items array
            me.items = [];
            
            // Build items array
            me._buildItems();
            
            // Cache groups
            me._cacheGroups();
            
            // Equalize!
            me.equalize();

            // return this so that we can chain and use the bridge with less code.
            return me;
        },
        options: {
            itemSelector: '.item',
            property: 'height',
            step: 0,
            images: false
        },
        equalize: function(clearValues) {
            var me = this;
            
            var i, itemGroups = me.itemGroups,
                itemGroupsLength = itemGroups.length;

            // Loop through groups
            $.each(itemGroups, function(i, val) {
                var itemGroup = val;
                
                // Clear/reset previous equalized styles
                if (clearValues) {
                    me.resetValues(itemGroup);
                }
                
                // Need to wait for images to load?
                if (me.options.images) {
                    
                    var a,
                        itemGroupLength = itemGroup.length,
                        items = [];
                    
                    // Get elements to build selector for imagesLoaded
                    for (a = 0; a < itemGroupLength; a++) {
                        var $itemElement = itemGroup[a].el;
                        items.push($itemElement);
                    }

                    $(items).imagesLoaded({
                        always: function($images) {
                            me._setMaxValue(itemGroup);
                        }
                    });
                    
                } else {
                    // Get and set max property dimension
                    me._setMaxValue(itemGroup);
                }
                
            });
        },
        resetValues: function(itemGroup) {
            var me = this,
                styles = {};
            
            if (itemGroup) {
                var length = itemGroup.length;

                for (i = 0; i < length; i++) {
                    var $element = $(itemGroup[i].el),
                        style = $element.attr('style'),
                        inlineValue = itemGroup[i].inlineValue,
                        styleReplaced;
                        
                    if (style) {
                        styleReplaced = style.replace(me.re, inlineValue);
                        
                        $element.attr('style', styleReplaced);
                    }
                }
            }
        },
        _buildItems: function() {
            var me = this,
                items = me.$elem.find(me.options.itemSelector);
            
            // Add to master items list
            me._addItems(items);
        },
        _addItems: function(collection) { // Pass in an array of jQuery elements
            var me = this;
            
            var i, collectionLength = collection.length;

            // Cycle groups for max property dimension
            for (i = 0; i < collectionLength; i++) {
                var item = {},
                    $element = $(collection[i]);
                
                item.el = $element;
                item.inlineValue = me._getInlineValue($element);
                
                me.items.push(item);
            }
        },
        _getInlineValue: function($element) {
            var me = this;
            
            var style = $element.attr('style'),
                propertyMatch,
                inlineValue = '';
            
            // If there some styles were returned
            if (style) {
                propertyMatch = style.match(me.re);
                
                // If there was a replacement
                if (propertyMatch) {
                    inlineValue = propertyMatch[0];
                }
            }
            
            return inlineValue;
        },
        _cacheGroups: function() {
            var me = this;

            me.itemGroups = [];

            var itemsLength = me.items.length,
                steppedArray = [],
                step = me.options.step;

            for (i = 0; i < itemsLength; i += step) {
                steppedArray = me.items.slice(i, i + step);
                me.itemGroups.push(steppedArray);
            }
        },
        _setMaxValue: function(itemGroup) {
            var me = this,
                styles = {},
                groupLength = itemGroup.length;
            
            // Get maximum property
            var maxProp = me._getMaxValue(itemGroup),
                i;
                
            styles[me.options.property] = maxProp;
            
            // Apply max property
            for (i = 0; i < groupLength; i++) {
                var element = itemGroup[i].el;
                element.css(styles);
            }
        },
        _getMaxValue: function(itemGroup) {
            var me = this;

            var heights = [],
                prop = me.options.property,
                groupLength = itemGroup.length;

            for (i = 0; i < groupLength; i++) {   
                var element = itemGroup[i].el;
                heights.push(parseInt(element.css(prop), 10));
            }

            return Math.max.apply(null, heights);
        },
        _bindings: function() {
            var me = this;
            
            me.$elem.on('equalize', function(ev, clearValues) {
                ev.stopPropagation();
                me.equalize(true);
            });
            
            $('body').on('equalize', function(ev) {
                me.$elem.trigger('equalize');
            });
        }
    };

    // Plugin definition
    $.plugin('equalize', equalize);

})(jQuery, window, document);