(function($, window, document, undefined) {
    var gridToggle = {
        init: function(options, elem) {
            var me = this;

            // Mix in the passed-in options with the default options
            me.options = $.extend({}, this.options, options);

            // Save the element reference, both as a jQuery reference and a normal reference
            me.elem = elem;
            me.$elem = $(elem);
            me.$wrapper = me.$elem.find(me.options.wrapperSelector);
            me.$toggleTrigger = me.$elem.find(me.options.triggerSelector);
            me.$controlWrapper = me.$elem.find(me.options.controlsWrapperSelector);
            
            // No controls? Bail out son!
            if (!me.$controlWrapper.length) {
                return;
            }
            
            // Add bindings
            me._bindings();
            
            // Build!
            me.build();
            
            // Return for chainability
            return me;
        },
        options: {
            triggerSelector: '.toggle',
            itemSelector: '.item',
            wrapperSelector: '.grid-wrapper',
            controlsWrapperSelector: '.grid-control-wrapper'
        },
        build: function() {
            var me = this;
            
            // Cache visible and hidden items
            me.$visibleItems = me.getItems(':visible');
            me.$hiddenItems = me.getItems(':hidden');
            
            // Get wrapper heights
            me.getWrapperHeights();
        },
        open: function() {
            var me = this;
            
            // Trigger show event
            var e = $.Event('open');
            me.$elem.trigger(e);
            
            // If already visible, or preventDefault() has been called return;
            if (me.isOpen || e.isDefaultPrevented()) {
                return;
            }
            
            var beforeAnimation = function() {
                me.$hiddenItems.show();
                
                me.$controlWrapper.animate({
                    'height': 0
                }, 250, 'easeOutExpo', function(){
                    $(this).css({
                        'height': 'auto'
                    }).hide();
                });
            };
            
            // Animate
            me._animateWrapper(beforeAnimation, null, me.wrapperHeightClosed, me.wrapperHeightOpen);
            
            // Set shown state
            me.isOpen = true;
        },
        close: function() {
            var me = this;
            
            // Trigger hide event
            var e = $.Event('close');
            me.$elem.trigger(e);
            
            if (!me.isOpen || e.isDefaultPrevented()) {
                return;
            }
            
            var afterAnimation = function() {
                me.$hiddenItems.hide();
            };
            
            // Animate
            me._animateWrapper(null, afterAnimation, me.wrapperHeightOpen, me.wrapperHeightClosed);
            
            me.isOpen = false;
        },
        getItems: function(filter) {
            var me = this;
            
            return me.$elem.find(me.options.itemSelector).filter(filter);
        },
        getWrapperHeights: function() {
            var me = this;
            
            me.wrapperHeightClosed = me._getWrapperHeightClosed();
            me.wrapperHeightOpen = me._getWrapperHeightOpen();
        },
        _getWrapperHeightClosed: function() {
            var me = this;
            
            // Hide default hidden items
            me.$hiddenItems.hide();
            
            // Equalize item heights
            me.$elem.trigger('equalize', [true]);
            
            return me._getWrapperHeightUtil(); 
        },
        _getWrapperHeightOpen: function() {
            var me = this;
            
            // Hide default hidden items
            me.$hiddenItems.show();
            
            // Equalize item heights
            me.$elem.trigger('equalize', [true]);
            
            // Calculate height
            var height = me._getWrapperHeightUtil();
            
            // Hide hidden items again
            me.$hiddenItems.hide();
            
            return height;
        },
        _getWrapperHeightUtil: function() {
            var me = this;
            
            return me.$wrapper.height();
        },
        _animateWrapper: function(beforeAnimation, afterAnimation, startHeight, endHeight) {
            var me = this;
            
            // add closed height
            me.$wrapper.css({
                'height': startHeight
            }).addClass('open');
            
            if (beforeAnimation instanceof Function) {
                beforeAnimation.call(this);
            }
            
            me.$wrapper.animate({
                'height': endHeight
            }, 500, 'easeOutExpo', function(){
                
                if (afterAnimation instanceof Function) {
                    afterAnimation.call(this);
                }
                
                $(this).height('auto');
            });
        },
        _bindings: function() {
            var me = this;
            
            me.$toggleTrigger.on('click.gridToggle', function(ev) {
                ev.preventDefault();
                
                if (!me.isOpen) {
                    me.open();
                } else {
                    me.close();
                }
            });
            
            // Add bindings from options
            var bindingOptions = me.options.bindings;
            
            if (bindingOptions instanceof Function) {
                bindingOptions.call(this);
            }
        }
    };

    // Plugin definition
    $.plugin('gridToggle', gridToggle);

})(jQuery, window, document);